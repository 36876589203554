.ms-track {
  background: transparent;
  opacity: 0;
  position: absolute;
  transition: background-color .3s ease-out, border .3s ease-out, opacity .3s ease-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

.ms-track.ms-track-show {
  opacity: 1
}

.ms-track.ms-y {
  border-left: 1px solid transparent;
  height: 100%;
  right: 0;
  top: 0;
  width: var(--ms-track-size, 16px)
}

.ms-track.ms-y.ms-active .ms-thumb,
.ms-track.ms-y:hover .ms-thumb {
  width: calc(var(--ms-track-size, 16px) - var(--ms-track-gutter, 2px)*2)
}

.ms-track.ms-y .ms-thumb {
  right: var(--ms-track-gutter, 2px);
  top: 0;
  transition: width .3s ease-out;
  width: 6px
}

.ms-track.ms-y .ms-thumb:hover {
  width: calc(var(--ms-track-size, 16px) - var(--ms-track-gutter, 2px)*2)
}

.ms-track.ms-y .ms-thumb:after {
  content: "";
  height: 100%;
  position: absolute;
  right: calc(var(--ms-track-gutter, 2px)*(-1));
  top: 0;
  width: var(--ms-track-size, 16px)
}

.ms-track.ms-x {
  border-top: 1px solid transparent;
  bottom: 0;
  height: var(--ms-track-size, 16px);
  left: 0;
  width: 100%
}


.ms-track.ms-x.ms-active .ms-thumb,
.ms-track.ms-x:hover .ms-thumb {
  height: calc(var(--ms-track-size, 16px) - var(--ms-track-gutter, 2px)*2)
}

.ms-track.ms-x .ms-thumb {
  bottom: var(--ms-track-gutter, 2px);
  height: 6px;
  left: 0;
  transition: height .3s ease-out
}

.ms-track.ms-x .ms-thumb:hover {
  width: calc(var(--ms-track-size, 16px) - var(--ms-track-gutter, 2px)*2)
}

.ms-track.ms-x .ms-thumb:after {
  bottom: calc(var(--ms-track-gutter, 2px)*(-1));
  content: "";
  height: var(--ms-track-size, 16px);
  left: 0;
  position: absolute;
  width: 100%
}

.ms-track.ms-active,
.ms-track:hover {
  background: var(--ms-track-background);
  border-color: var(--ms-track-border-color);
  opacity: 1
}

.ms-track.ms-active {
  z-index: 20
}

.ms-track .ms-thumb {
  background: var(--ms-thumb-color);
  border-radius: 6px;
  position: absolute;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

.ms-container {
  scrollbar-width: none
}

.ms-container::-webkit-scrollbar {
  display: none
}

.ms-track-box {
  left: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 100%;
  z-index: var(--ms-z-index, 20)
}

.ms-track-global {
  position: relative;
  z-index: var(--ms-z-index, 20)
}

.ms-track-global .ms-track {
  position: fixed
}

.ms-theme-light {
  --ms-track-background: hsla(0, 0%, 97%, .76);
  --ms-track-border-color: #dfdfdf;
  --ms-thumb-color: rgba(0, 0, 0, .5)
}

.ms-theme-dark {
  --ms-track-background: hsla(0, 0%, 82%, .14);
  --ms-track-border-color: hsla(0, 0%, 89%, .32);
  --ms-thumb-color: hsla(0, 0%, 100%, .5)
}
