// #navpage-topbar{
//   div{
//     background-color: #fff;
//   }
// }

#home{
  background-color: black;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 100vw;

  .home-p{
    height: 60vh;
    width: 15vw;
    margin: 0 1vw;
    transform-origin: left;
    transition: all .6s;
  }
  #home-p1{
    background-color: #EFD55E;
    p{
      margin-left: 2vw;
      width: 5vw;
      font-size: 5vw;
      font-weight: 900;
      word-wrap: break-word;  
    }
  }
  #home-p2{
    background-color: #89D0C2;
    p{
      text-align: right;
      font-size: 5vw;
      font-weight: 500;
    }
  }
  #home-p3{
    background-color: #F77A82;
  }
  #home-p4{
    background-color: #B7AACB;
    p{
      font-size: 10vh;
      line-height: 10vh;
      font-weight: 900;
    }
    #home-p4-1, #home-p4-3, #home-p4-5{
      text-align: left;
      margin-left: 5vw;
    }
    #home-p4-2, #home-p4-4, #home-p4-6{
      text-align: right;
      margin-right: 1vw;
    }
  }
  #home-p5{
    background-color: #077ABD;
    text-align: center;
    #home-p5-1{
      font-size: 8vh;
      font-weight: 900;
      margin-top: 3vh;
    }
    #home-p5-2{
      font-size: 4vh;
      font-weight: 900;
    }
    .home-p5-squa{
      width: 1.5vw;
      height: 1.5vw;
      background-color: black;
      margin-left: 11vw;
      margin-bottom: 3vw;
    }
    #home-p5-squa-1{
      margin-top: 10vh;
    }
  }
  #home-p1:hover{
    transform: rotateY(64deg);
  }
  #home-p2:hover{
    transform: rotateY(53deg);
  }
  #home-p3:hover{
    transform: rotateY(43deg);
  }
  #home-p4:hover{
    transform: rotateY(34deg);
  }
  #home-p5:hover{
    transform: rotateY(28deg);
  }
  .home-p:hover{
    background-color: #fff!important;
  }
}
