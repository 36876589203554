#note-content {
  margin: 4vh 14vw 0 14vw;
  width: 72vw;
  // width: 1000px;
  min-height: 100vh;
  position: relative;
}

#note-content-right {
  width: 20vw;
  position: absolute;
  right: 0;
  top: 0;
}

#note-content-right-fixed {
  width: 20vw;
  position: fixed;
  // right: 10vw;
  left: 66vw;
  top: 4vh;
}

.note-content-right-hidden {
  visibility: hidden;
}
