#topbar{
  width: 100%;
  height: 4vh;
  /* background-color: rgb(10, 12, 18); */
  background-color: rgba(255,255,255,0);
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 500;
}

#topbar-left, #topbar-right {
  cursor: pointer;
}

#topbar-left{
  /* background-color: aqua; */
  width: 10vh;
  height: 6vh;
}

#topbar-left svg{
  /* background-color: blue; */
  width: 3vh;
  height: 3vh;
  padding-left: 2vh;
  padding-top: 1.5vh;
}

#topbar-middle{
  color: white;
  width: 8vh;
  height: 10vh;
  background-color: rgb(10, 12, 18);
  clip-path: polygon(0 0, 100% 0, 100% 60%, 50% 100%, 0 60%)
}

#topbar-right{
  /* background-color: aquamarine; */
  width: 10vh;
  height: 6vh;
}

#topbar-right svg{
  width: 3vh;
  height: 3vh;
  padding-left: 5vh;
  padding-top: 1.5vh;
}
