#note-content-aboutme{
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 1vw;
  padding: 1.5vw 0;
  text-align: center;

}

#note-content-aboutme-img{
  margin: 0 7vw;
  width: 6vw;
  height: 6vw;
  background-color: #000;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
  }
}

#note-content-aboutme-name{
  font-size: 2.3vh;
  font-weight: 500;
  margin-top: 8px;
}

#note-content-aboutme-total{
  display: flex;
  justify-content: space-around;
  margin: 2.3vh 0;
  div{
    font-size: 2vh;
    line-height: 3vh;
  }
}

#note-content-aboutme-btn{
  width: 15vw;
  height: 2.3vw;
  margin: 0 2.5vw;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  background-color: #71838F;
  // background-color: #B7E0ff;
  box-sizing: border-box;
  border: #71838F 3px solid;
  // line-height: 2.3vw;
  line-height: calc(2.3vw - 6px);
  color: #fff;
  font-weight: 800;
}

#note-content-aboutme-btn:hover{
  color: #000;
  background-color: #fff;
  border-radius: 1.15vw;
}

#note-content-aboutme-relate{
  padding-left: 1vw;
  width: 13vw;
  height: 2.3vw;
  margin: 2.3vh 2.5vw 0 2.5vw;
  display: flex;
  justify-content: space-around;
  // background-color: #000;

  .note-content-aboutme-relate-clickbox {
    width: 2vw;
    height: 2vw;
    border-radius: 1vw;
    overflow: hidden;

    svg {
      border-radius: 50%;
      fill: #333333;
      width: 2vw;
      height: 2vw;
    }
  
    svg:hover {
      fill: #fff;
      background-color: #333;
    }
  }
}
