#key-code {
  height: 100vh;

  pre {
    background-color:beige;
    white-space: pre-wrap;
    font-size: 36px;
    word-break: break-word
  }
}
