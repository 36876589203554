#note-content-life{
  margin-top: 2vh;
  width: 100%;
  height: 100%;
  border-radius: 1vw;
  background-color: white;
  text-align: center;
  padding: 1.5vw 0;


  #note-content-life-p1{
    font-size: 4vh;
    font-weight: 900;
  }
  
  #note-content-life-p2{
    font-size: 2vh;
    font-weight: 300;
  }

  #note-content-life-p3{
    font-size: 5vh;
    letter-spacing: 0.4vh;
    font-family: monospace;
  }

}

