#note-content-love {
  margin-top: 2vh;
  width: 100%;
  height: 100%;
  border-radius: 1vw;
  background-color: white;
  text-align: center;
  padding: 1.5vw 0;
  box-shadow:rgba(0,0,0,.1) 0px 0px 15px;

  &:hover {
    color: #ff6bb5;
    font-weight: 900;
    box-shadow:rgba(255,107,181,.2) 0px 0px 20px;
  }

  #note-content-love-p3 {
    font-size: 5vh;
    letter-spacing: 0.4vh;
    font-family: monospace;
  }
}

