/**
新样式补丁
在 @pages/index.ts 中引入
*/

// note页图片下渐变
// #note-banner {
//   box-shadow:rgba(135,206,250,.6) 0px 0px 150px;
// }

// 博客列表模块边缘
#note-content-aboutme,#note-content-life,#note-content-motto,#note-content-totaltag,#note-content-notes-noteitems {
  box-shadow:rgba(0,0,0,.1) 0px 0px 15px;
}

// 博客正文模块边缘
#inNote-editor,#inNote-catlog {
  box-shadow:rgba(0,0,0,.2) 0px 0px 20px;
}

// 新背景色
#note,#inNote {
  background-color: #f0f8ff;
}

// note页按钮颜色
#note-content-aboutme-btn {
  background-color: #87ceeb;
  border: #87ceeb 3px solid;
}

// note页时间
#note-content-life:hover {
  color: red;
  font-weight: 900;
  box-shadow:rgba(255,0,0,.2) 0px 0px 20px;
}

// 未定义页面
.unfinished-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-weight: 900;

  img {
    width: 500px;
  }
}

// // 滚动条
// ::-webkit-scrollbar {
//   background-color: #f1f1f1;
//   overflow: visible;
//   width: 3px;
//   height: 3px;
// }
// ::-webkit-scrollbar-thumb {
//   background-color: rgba(0, 0, 0, .2);
//   background-clip: padding-box;
//   min-height: 15px;
//   box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07);
// }
// ::-webkit-scrollbar-thumb:vertical:hover {
//   background-color: rgba(0, 0, 0, .3);
// }
// ::-webkit-scrollbar-thumb:vertical:active {
//   background-color: rgba(0, 0, 0, .5);
// }
// ::-webkit-scrollbar-button {
//   height: 0;
//   width: 0;
// }
// ::-webkit-scrollbar-track {
//   background-clip: padding-box;
//   border: solid transparent;
//   border-width: 0 0 0 4px;
// }
// ::-webkit-scrollbar-corner {
//   background: transparent;
// }
