#inNote-editor * {
  -webkit-user-select: text !important;
  -khtml-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

#inNote {

  // height: 87vh;
  min-height: 74vh;
  padding: 13vh 0;
  background-color: #eee;
  
  #inNote-editor {
    // box-sizing: border-box;
    padding: 7vh 5vw;
    background-color: #fff;
    border-radius: 1vw;
    min-height: 60vh;
  }

  .inNote-editor-pc {
    width: 50vw;
    margin-left: 10vw;
  }
  
  .inNote-editor-mobile {
    width: 90vw;
    margin: auto;
  }

  #inNote-catlog {
    box-sizing: border-box;
    font-size: 2vh;
    line-height: 4vh;
    letter-spacing: 0.1vh;
    font-weight: 600;
    position: fixed;
    overflow: scroll;
    white-space: nowrap;
    background-color: #fff;
    border-radius: 1vw;
    border: 10px solid #fff;
    padding: 20px;
    width: 15vw;
    height: 74vh;
    right: 10vw;
    top: 13vh;

    div a{
      text-decoration:none;
      color:#333;
    }

    div a:hover{
      color: #888;
    }

  }

  #inNote-catlog::-webkit-scrollbar {
    width: 0;
    background: rgba(0, 0, 0, 0);
    display: none;
  }
}

.inNote-editor-no-catlog {
  display: flex;
  justify-content: center;
  #inNote-editor {
    margin-left: 0;
  }
}
